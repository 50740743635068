export const API_ENDPOINTS: Record<string, string> = {
  PRODUCTS: 'products',
  PRODUCTS_IMAGES: 'products/images',
  SETTINGS: 'settings',
  CUSTOMER: 'me',
  CUSTOMERS: 'users',
  COUPONS: 'coupons',
  CATEGORIES: 'categories',
  TYPE: 'types',
  UPLOAD: 'attachments',
  ORDERS: 'orders',
  ORDER_STATUS: 'order-status',
  LOGIN: 'token',
  SOCIAL_LOGIN: 'social-login-token',
  SEND_OTP_CODE: 'send-otp-code',
  VERIFY_OTP_CODE: 'verify-otp-code',
  OTP_LOGIN: 'otp-login',
  UPDATE_CONTACT: 'update-contact',
  REGISTER: 'register',
  FORGET_PASSWORD: 'forget-password',
  LOGOUT: 'logout',
  CHANGE_PASSWORD: 'change-password',
  RESET_PASSWORD: 'reset-password',
  VERIFY_FORGET_PASSWORD: 'verify-forget-password-token',
  VERIFY_CHECKOUT: 'orders/checkout/verify',
  CONTACT: 'contact-us',
  ADDRESS: 'address',
  ADDRESS_CITY: 'address/city',
  ADDRESS_WAREHOUSE: 'address/warehouse',
  ADDRESS_STREET: 'address/street',
  SHOPS: 'shops',
  ORDER_TRACKING_NUMBER: 'tracking-number',
  REFUNDS: 'refunds',
  AUTH: 'otp',
  CUSTOMER_CONTACT: 'me/contacts',
  CUSTOMER_ADDRESS: 'me/addresses',
  CONTENT: 'content',
  CART: 'cart',
  VERIFY_CART: 'verify',
  GET_ORDER_STATUS: 'orders/:track/status'
};
