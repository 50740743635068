import { useTranslation } from 'next-i18next';
import React from 'react';

const Footer = () => {
  const { t } = useTranslation('common');
  return (
    <footer
      className="site-header-with-search h-24 sm:h-14 flex mt-6"
       style={{
         zIndex: 49, backgroundColor: 'rgba(var(--color-light), var(--tw-bg-opacity))', top: 0,
       }}>
        <div className="inline-flex flex-row overflow-hidden relative w-32 md:w-40 h-10 mr-6 items-center justify-center sm:justify-end" style={{alignItems: "center", width: "100%"}}>
            <img src="/images/visa.svg" className="mr-2" style={{height: '40px'}}/>
            <img src="/images/mc-logo-52.svg" className="mr-2" style={{height: '20px'}}/>
            <img src="/images/logo_liqpay.svg" style={{height: '15px'}}/>
        </div>
    </footer>
  );
};

export default Footer;
