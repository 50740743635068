import { BaseService } from '@framework/utils/base-service';
import { API_ENDPOINTS } from '@framework/utils/endpoints';
import { authorizationAtom } from '@store/authorization-atom';
import { useAtom } from 'jotai';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import isEmpty from "lodash/isEmpty";
import Cookies from 'js-cookie';
import {SECURE_AUTH_TOKEN} from "@lib/constants";

class CustomerService extends BaseService {}
const customerService = new CustomerService(API_ENDPOINTS.CUSTOMER);
export const fetchMe = async () => {
  const { data } = await customerService.findAll();
  return { me: data };
};

export const useCustomerQuery = (
  options: UseQueryOptions<any, Error, any, QueryKey>
) => useQuery<any, Error>(API_ENDPOINTS.CUSTOMER, fetchMe, options);
const useUser = () => {
  const [isAuthorized] = useAtom(authorizationAtom);
  const { data, isLoading, error } = useCustomerQuery({
    enabled: isAuthorized,
    onError: (err) => {
      console.log("GET USER ERROR:", err, isAuthorized);
      Cookies.remove(SECURE_AUTH_TOKEN);
    },
  });
  const result = {};
  if (isEmpty(data?.me) || !data?.me?.id) {
    console.log("GET USER ERROR:", isAuthorized, data, error);
    if (error) {
      Cookies.remove(SECURE_AUTH_TOKEN);
      return {me: null, loading: isLoading, error};
    }
  }
  // if (data?.me?.addresses) data.me.addresses = data.me.addresses.map(address => ({...address, anotherData: address.anotherData && address.anotherData[0] === '{' ? JSON.parse(address.anotherData) : null }))
  return  { me: data?.me, loading: isLoading, error };

};

export default useUser;
