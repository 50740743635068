import cn from 'classnames';
import styles from './spinner.module.css';

interface Props {
  className?: string;
  text?: string;
  showText?: boolean;
  simple?: boolean;
}

const Spinner = ({ className = null, showText = true, text = 'Loading', simple = false }: Props) => (
    <>
      {simple ? (
        <span className={cn(className, styles.simple_loading)} />
      ) : (
        <span
          className={cn(
            'w-full flex flex-col items-center justify-center h-screen',
            className
          )}
        >
          <span className={styles.loading} />

          {showText && (
            <h3 className="text-lg font-semibold text-body italic">{text}</h3>
          )}
        </span>
      )}
    </>
  );

Spinner.defaultProps = {
  className: null,
  text: null,
  showText: true,
  simple: false,
}

export default Spinner;
