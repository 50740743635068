import axios from 'axios';
import { getToken } from './get-token';

const request = axios.create({
  baseURL: process.env.REST_API_ENDPOINT || '/api/', // TODO: take this api URL from env
  timeout: 30000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// Change request data/error here
request.interceptors.request.use(
  (config) => {
    const token = getToken();
    console.log("TOKEN:", config.url, token)
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token || ''}`,
    };
    return config;
  },
  (error) => Promise.reject(error)
);

export default request;
