import NextImage from 'next/image';

function loader({src, width, quality}) {
  return src;
}

function Image({src, ...rest}) {
  return <NextImage
    unoptimized placeholder="blur"
    blurDataURL="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NTAgMzAwIiBjbGFzcz0iaW1nLWZsdWlkIHJvdW5kZWQgbXgtYXV0byBkLWJsb2NrIiB3aWR0aD0iNDUwIiBoZWlnaHQ9IjMwMCI+CiAgICAgICAgICAgICAgICAgICAgPHJlY3Qgd2lkdGg9IjQ1MCIgaGVpZ2h0PSIzMDAiIGZpbGw9IiNmMGYwZjAiPjwvcmVjdD4KICAgICAgICAgICAgICAgICAgICA8dGV4dCB4PSI1MCUiIHk9IjUwJSIgZG9taW5hbnQtYmFzZWxpbmU9Im1pZGRsZSIgdGV4dC1hbmNob3I9Im1pZGRsZSIgZm9udC1mYW1pbHk9Im1vbm9zcGFjZSIgZm9udC1zaXplPSIyNnB4IiBmaWxsPSIjMzMzMzMzIj4uLi48L3RleHQ+ICAgCiAgICAgICAgICAgICAgICA8L3N2Zz4KICAgICAgICAgICAgICAgIDwhLS08YSBkb3dubG9hZD0iRklMRU5BTUUucG5nIiBocmVmPSJkYXRhOmltYWdlL3BuZztiYXNlNjQsYXNkYXNkLi4uIj5Eb3dubG9hZDwvYT4tLT4="
    loader={loader}
    src={src}
    {...rest}
  />
}

export {
  Image
};

